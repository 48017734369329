import api from "@/utils/api";

const url = {
  chargeRule: "/client/cliChargeRule/list", // 收费规则
  houseTree: "/client/cliHouse/getHouseTree", // 区域列表（包括车位和房屋）
  chargeProject: '/client/cliChargeProject/list', // 收费项目下拉
}

const state = {
  chargeRuleOption: null, // 收费规则
  houseTreeOption: null, // 区域列表 (除租赁)
  chargeProjectOption: null, // 收费项目下拉
}

const getters = {
  chargeRuleOption: state => {
    return state.chargeRuleOption // 收费规则
  },
  houseTreeOption: state => {
    return state.houseTreeOption // 区域列表 （包括车位和房屋）
  },
  chargeProjectOption: state => {
    return state.chargeProjectOption // 收费项目下拉
  },
}

const mutations = {
  saveChargeRuleOption(state, chargeRuleOption) {
    state.chargeRuleOption = chargeRuleOption  // 收费规则
  },
  saveHouseTreeOption(state, houseTreeOption) {
    state.houseTreeOption = houseTreeOption // 区域列表 (除租赁)（包括车位和房屋）
  },
  saveChargeProjectOption(state, chargeProjectOption) {
    state.chargeProjectOption = chargeProjectOption // 收费项目下拉
  },
}

const actions = {
  // 收费规则
  getChargeRuleOption({commit, state}, refresh = false) {
    if(!state.chargeRuleOption || refresh) {
      api.post(url.chargeRule, {}).then((res) => {
        commit("saveChargeRuleOption", res.rows)
      })
    }
  },
  // 区域列表 (除租赁)（包括车位和房屋）
  getHouseTreeOption({commit, state}, refresh = false) {
    if(!state.houseTreeOption || refresh) {
      api.get(url.houseTree, {}).then((res) => {
        commit("saveHouseTreeOption", res.rows)
      })
    }
  },
  // 收费项目下拉
  getChargeProjectOption({commit, state}, refresh = false) {
    if(!state.chargeProjectOption || refresh) {
      api.post(url.chargeProject, { costEffective: "1" }).then((res) => {
        commit("saveChargeProjectOption", res.rows)
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
