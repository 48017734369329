const state = {
  noticeMsg: null,
}

const getters = {
  noticeMsg: state => {
    return state.noticeMsg
  },
}

const mutations = {
  saveNoticeMsg(state, noticeMsg) {
    state.noticeMsg = noticeMsg
  },
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
