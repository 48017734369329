export default [
    {
        path: '/menu',
        name: 'menu',
        component: () => import('@/views/systemManagement/menu/index'),
        meta: {
            title: '菜单管理',
        }
    },
    {
        path:'/user',
        name:'user',
        component:()=>import('@/views/systemManagement/user/index'),
        meta:{
            title:'员工管理'
        }
    },
    {
        path: '/dept',
        name: 'dept',
        component: () => import('@/views/systemManagement/dept/index'),
        meta: {
            title: '部门管理'
        }
    },
    {
        path: '/role',
        name: 'role',
        component: () => import('@/views/systemManagement/role/index'),
        meta: {
            title: '角色管理'
        }
    },
    {
        path: '/post',
        name: 'post',
        component: () => import('@/views/systemManagement/post/index'),
        meta: {
            title: '岗位管理'
        }
    },
]