import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
//左边路由
let baseIndex = {
  path: '/',
  alias: '/index',
  component: () => import('@/views/index'),
};
//你可以直接在这里定义系统功能路由，同样也可以在module文件夹下编写js定义
let children = [
  {
    path: '/',
    redirect: {
      name: "login"
    }
  },
  {
    path: '/classicIndex',
    name: 'classicIndex',
    component: () => import('@/views/home/index'),
    meta: {
      title: '首页'
    }
  }
];
//引入其他路由
const moduleFiles = require.context("./module", true, /\.js$/);
moduleFiles.keys().reduce((modules, modulePath) => {
  const value = moduleFiles(modulePath);
  if (Object.prototype.toString.call(value.default) === "[object Array]") {
    children = children.concat(value.default);
  }
}, {});
baseIndex.children = children;
// 基础路由
let baseRouteList = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  }, {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import('@/views/login/userAgreement')
  },{
    path: '/registerModal',
    name: 'registerModal',
    component: () => import('@/views/login/registerModal')
  },{
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/error.vue')
  }
];
baseRouteList.push(baseIndex);


export default new Router({
  mode: 'history',
  routes: baseRouteList
})
