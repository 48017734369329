//读取配置文件属性
const configFiles = require.context("../config", true, /\.js$/);
const configs = configFiles.keys().reduce((configs, configPath) => {
    const value = configFiles(configPath);
    configs = Object.assign(configs, value.default);
    if (process.env.NODE_ENV === "development") {
        for (let key in value.default) {
            if (key.indexOf("dev_") != -1) {
                let currentKey = key.replace("dev_", "");
                configs[currentKey] = value.default[key];
                delete configs[key];
            }
        }
    }
    return configs;
}, {});

export default configs;