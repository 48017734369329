export default [
    {
        path: '/voucher',
        name: 'voucher',
        component: () => import('@/views/marketingManagement/voucher/index.vue'),
        meta: {
            title: '抵扣券管理',
            keepAlive: true
        }
    },
    {
        path: '/couponUseDetails',
        name: 'couponUseDetails',
        component: () => import('@/views/marketingManagement/voucher/couponUseDetails.vue'),
        meta: {
            title: '用券明细',
            keepAlive: true
        }
    }
]