export default [
    {
        path: '/settledBusinesses',
        name: 'settledBusinesses',
        component: () => import('@/views/businesses/settledBusinesses/index'),
        meta: {
            title: '入驻商户',
        }
    }, {
        path: '/infoBusinesses',
        name: 'infoBusinesses',
        component: () => import('@/views/businesses/infoBusinesses/index'),
        meta: {
            title: '商户信息',
            keepAlive: true
        }
    },
]