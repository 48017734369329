import api from "@/utils/api";
import router from "@/route/router";
// import { Message } from 'element-ui';
// import {getGlobalOption} from "@/store/modules/option/option";

const state = {
  webList: null,
  userInfo: null,  // 当前用户信息
  company: null, // 公司信息
  communityInfo: null, // 社区信息
  communityConfig: null,// 社区配置项
  popup: false,// 消息提醒 每天第一次登录
  flush: false, // 刷新
}

const getters = {
  // footer信息
  webList: state => {
    return state.webList
  },
  userInfo: state => {
    return state.userInfo
  },
  company: state => {
    return state.company
  },
  popup: state => {
    return state.popup // 社区配置项
  },
  flush: state => {
    return state.flush
  }
}

const mutations = {
  saveWebList(state, webList) {
    state.webList = webList
  },
  saveUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  saveCompany(state, company) {
    state.company = company
  },
  savePopup(state,popup){
    state.popup = popup
  },
  saveFlush(state,flush){
    state.flush = flush
  }
}

const actions = {
  // footer信息
  async getWbeList({ state, commit }) {
    if (!state.webList) {
      let res = await api.get("/hm/menu/footer");
      if (res && res.code == 200) {
        commit("saveWebList", res.data);
      }
      return state.webList;
    }
  },
  // 用户信息
  getUserInfo({commit}){
    api.get('/hm/staff/getInfo').then(res=>{
      commit("saveUserInfo", res.data)
    })
  },
  // 获取公司信息
  getCompany({commit,state}, refresh) {
    if(!state.company  || refresh) {
      api.get("/hm/company/info")
          .then((res) => {
            res.data._serverType = res.data.serverType===1?'家政':'暂无'
            commit("saveCompany", res.data)
          })
    }
  },
  // 退出登录
  logOut(){
    api.delete('/hm/login/logout').then( res => {
      if(res && res.code == 200){
        router.replace('/login')
      }
    })
  },
  // 清除下拉数据
  clearOption({commit}) {
    commit('property/saveHouseTree', null)
    commit('property/saveAreaOption', null)
    commit('property/saveBuildOption', null)
    commit('property/saveParkOption', null)
    commit('property/saveParkingOption', null)
    commit('property/saveCurrentPark', null)
    commit('property/saveIntakeOption', null)
    commit('property/saveParkingTree', null)
    commit('system/saveGroupOption', null)
    commit('system/saveNoBindCommunityOption', null)
    commit('system/saveRoleOption', null)
    commit('system/saveDeptOption', null)
    commit('system/saveCommunityOption', null)
    commit('system/savePostOption', null)
    commit('system/saveUserOption', null)
    commit('charge/saveChargeRuleOption', null)
    commit('charge/saveHouseTreeOption', null)
    commit('charge/saveChargeProjectOption', null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
