export default [
  {
    path: '/basicInformation',
    name: 'basicInformation',
    component: () => import('@/views/cliUser/basicInformation/index'),
    meta: {
      title: '基本资料',
    },
  },
  {
    path: '/merchantInformation',
    name: 'merchantInformation',
    component: () => import('@/views/businesses/infoBusinesses/index'),
    meta: {
      title: '商户信息',
    },
  },
  {
    path: '/contractManagement',
    name: 'contractManagement',
    component: () => import('@/views/cliUser/contractManagement/index'),
    meta: {
      title: '签约管理',
    },
  },
  {
    path: '/detailsOrder',
    name: 'detailsOrder',
    component: () => import('@/views/cliUser/contractManagement/detailsOrder/detailsOrder'),
    meta: {
      title: '服务明细',
    },
  },
  {
    path: '/baseOrder',
    name: 'baseOrder',
    component: () => import('@/views/cliUser/contractManagement/baseOrder/baseOrder'),
    meta: {
      title: '服务订单',
    },
  },
]