import api from "@/utils/api";

const url = {
  role: "/hm/role/list", // 角色
  dept: "/hm/dept/list", // 部门
  post: "/hm/post/list", // 岗位
  user: "/hm/role/list", // 员工
}

const state = {
  roleOption: null, // 角色
  deptOption: null, // 部门
  postOption: null, // 岗位
  userOption: null, // 员工

}

const getters = {
  roleOption: state => {
    return state.roleOption // 角色
  },
  deptOption: state => {
    return state.deptOption // 部门
  },
  postOption: state => {
    return state.postOption // 岗位
  },
  userOption: state => {
    return state.userOption // 员工
  },
}

const mutations = {
  saveRoleOption(state, roleOption) {
    state.roleOption = roleOption  // 角色
  },
  saveDeptOption(state, deptOption) {
    state.deptOption = deptOption   // 部门
  },
  savePostOption(state, postOption) {
    state.postOption = postOption   // 岗位
  },
  saveUserOption(state, userOption) {
    state.userOption = userOption   // 员工
  },
}

const actions = {
  // 获取 角色 下拉
  getRoleOption({commit, state}, refresh = false) {
    if(!state.roleOption || refresh) {
      api.post(url.role, {}).then((res) => {
        commit("saveRoleOption", res.rows)
      })
    }
  },
  // 获取 部门
  getDeptOption({commit, state}, refresh = false) {
    if(!state.deptOption || refresh) {
      api.post(url.dept, {}).then((res) => {
        commit("saveDeptOption", res.rows)
      })
    }
  },
  // 获取 岗位
  async getPostOption({commit, state}, refresh = false) {
    if(!state.postOption || refresh) {
      api.post(url.post, {}).then((res) => {
        commit("savePostOption", res.rows)
      })
    }
  },
  // 获取 员工
  async getUserOption({commit, state}, refresh = false) {
    if(!state.userOption || refresh) {
      api.post(url.user, {}).then((res) => {
        let rows = res.rows
        rows.forEach((item) => {
          item.nameOption = item.userName + '-' +  item.nickName
        })
        commit("saveUserOption", res.rows)
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
