import Vue from "vue"
import Vuex from "vuex"
import admin from './modules/admin/admin'
import routers from "@/store/modules/admin/routers";
import system from "@/store/modules/option/system";
import property from "@/store/modules/option/property";
import charge from "@/store/modules/option/charge";
import payment from "@/store/modules/sockets/payment"
import appStore from "@/store/modules/option/appStore"

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        admin,
        routers,
        system,
        property,
        charge,
        payment,
        appStore,
    },
})

