// import api from "@/utils/api";

// const url = {
//   appDescribe: "/client/cliChargeRule/list", // app应用描述
// }

const state = {
  appDescribe: '<h1>暂无描述</h1>', // app应用描述
  appInfo: null, // app信息
}

const getters = {
  appDescribe: state => {
    return state.appDescribe // app应用描述
  },
  appInfo: state => {
    return state.appInfo // app信息
  },
}

const mutations = {
  saveAppDescribe(state, appDescribe) {
    state.appDescribe = appDescribe  // app应用描述
  },
  saveAppInfo(state, appInfo) {
    // console.log(appInfo, 'app')
    state.appInfo = appInfo  // app信息
  },

}

const actions = {


}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
