import api from "@/utils/api";
const url = {
  area: "/client/cliCommunity/areaList", // 当前社区 的区域列表
  build: "/client/cliCommunity/getBuild", // 当前社区 的楼栋列表
  intake: "/client/cliIntake/list", // 当前社区入住人员
  park: "/client/cliVehicle/getPark",  // 根据商户名查询所有车场
  currentPark: "/client/cliVehicle/getCurrentPark", // 获取当前车场
  parking: "/client/cliBuildingForParking/list", // 获取车场位置列表
  parkingTree: "/client/cliHouseForParking/getHouseForParkingTree", // 纯车场树
  houseTree: "/client/cliHouse/getHouseTree2", // 获取纯房屋树
}

const state = {
  areaOption: null, // 当前社区 的区域列表
  buildOption: null, // 当前社区 的楼栋列表
  intakeOption: null, // 当前社区入住人员
  parkOption: null, // 根据商户名查询车场
  currentPark: null, // 获取当前车场
  parkingOption: null, // 获取车场位置列表
  parkingTree: null, // 纯车场树
  houseTree: null, // 获取纯房屋树
}

const getters = {
  areaOption: state => {
    return state.areaOption // 当前社区 的区域列表
  },
  buildOption: state => {
    return state.buildOption // 当前社区 的楼栋列表
  },
  intakeOption: state => {
    return state.intakeOption // 当前社区 入住人员
  },
  parkOption: state => {
    return state.parkOption // 根据商户名查询车场
  },
  currentPark: state => {
    return state.currentPark // 根据商户名查询车场
  },
  parkingOption: state => {
    return state.parkingOption // 获取车场位置列表
  },
  parkingTree: state => {
    return state.parkingTree // 纯车场树
  },
  houseTree: state => {
    return state.houseTree // 获取纯房屋树
  },
}

const mutations = {
  saveAreaOption(state, areaOption) {
    state.areaOption = areaOption  // 当前社区 的区域列表
  },
  saveBuildOption(state, buildOption) {
    state.buildOption = buildOption  // 当前社区 的楼栋列表
  },
  saveIntakeOption(state, intakeOption) {
    state.intakeOption = intakeOption  // 当前社区 入住人员
  },
  saveParkOption(state, parkOption) {
    state.parkOption = parkOption  // 根据商户名查询车场
  },
  saveCurrentPark(state, currentPark) {
    state.currentPark = currentPark  // 根据商户名查询车场
  },
  saveParkingOption(state, parkingOption) {
    state.parkingOption = parkingOption  // 获取车场位置列表
  },
  saveParkingTree(state, parkingTree) {
    state.parkingTree = parkingTree // 纯车场树
  },
  saveHouseTree(state, houseTree) {
    state.houseTree = houseTree  // 获取纯房屋树
  },

}

const actions = {
  // 当前社区 的区域列表
  getAreaOption({commit, state}, refresh=false) {
    if(!state.areaOption || refresh) {
      api.get(url.area)
        .then((res) => {
          commit("saveAreaOption", res.data )
        })
    }
  },
  // 当前社区 的楼栋列表
  getBuildOption({commit, state}, refresh=false) {
    if(!state.buildOption || refresh) {
      api.get(url.build)
        .then((res) => {
          commit("saveBuildOption", res.data )
        })
    }
  },
  // 当前社区 入住人员
  getIntakeOption({commit},  id) {
    api.post(url.intake, {communityId: id})
      .then((res) => {
        let rows = res.rows
        rows.forEach((item) => {
          item.name = item.building + '-' + item.roomNumber + '-' + item.intakeName
        })
        commit("saveIntakeOption", res.rows )
      })
  },
  // 根据商户名查询车场
  getParkOption({commit, state}, refresh=false) {
    if(!state.parkOption || refresh) {
      api.get(url.park)
        .then((res) => {
          // console.log('所有车场', res);
          commit("saveParkOption", res.data )
        })
    }
  },
  // 当前社区车场
  getCurrentPark({commit, state}, refresh=false) {
    if(!state.currentPark || refresh) {
      // console.log("url.currentPark",url.currentPark)
      api.get(url.currentPark)
        .then((res) => {
          // console.log('当前车场', res);
          commit("saveCurrentPark", res.data || {})
        }).catch(() => {
          commit("saveCurrentPark", {})
      })
    }
  },
  // 获取车场位置列表
  getParkingOption({commit, state}, refresh=false) {
    if(!state.parkingOption || refresh) {
      api.post(url.parking,{})
        .then((res) => {
          commit("saveParkingOption", res.rows || {})
        })
    }
  },
  // 纯车场下拉
  getParkingTree({commit, state}, refresh=false) {
    if(!state.parkingTree || refresh) {
      api.get(url.parkingTree,{})
        .then((res) => {
          commit("saveParkingTree", res.rows || {})
        })
    }
  },
  // 获取纯房屋树
  getHouseTree({commit, state}, refresh=false) {
    if(!state.houseTree || refresh) {
      api.get(url.houseTree,)
        .then((res) => {
          commit("saveHouseTree", res.rows || {})
        })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
