export default [
    {
        path: '/serviceStaff',
        name: 'serviceStaff',
        component: () => import('@/views/personnelManagement/serviceStaff/index'),
        meta: {
            title: '服务人员',
            keepAlive: true,
        }
    },
    {
        path: '/commodity',
        name: 'commodity',
        component: () => import('@/views/personnelManagement/commodity/index'),
        meta: {
            title: '商品列表',
            keepAlive: true,
        }
    },
    {
        path: '/commodityAdd',
        name: 'commodityAdd',
        component: () => import('@/views/personnelManagement/commodity/commodityAdd'),
        meta: {
            title: '新增商品',
            keepAlive: true,
        }
    },
    {
        path: '/commodityEdit',
        name: 'commodityEdit',
        component: () => import('@/views/personnelManagement/commodity/commodityEdit'),
        meta: {
            title: '编辑商品',
            keepAlive: true,
        }
    },
    {
        path: '/appreciation',
        name: 'appreciation',
        component: () => import('@/views/personnelManagement/appreciation/index.vue'),
        meta: {
            title: '增购服务',
            keepAlive: true,
        }
    },
    {
        path: '/shopCouponCode',
        name: 'shopCouponCode',
        component: () => import('@/views/personnelManagement/shopCouponCode/index.vue'),
        meta: {
            title: '优惠码管理',
            keepAlive: true,
        }
    },
    {
        path: '/activityManage',
        name: 'activityManage',
        component: () => import('@/views/personnelManagement/activityManage/index.vue'),
        meta: {
            title: '活动管理',
            keepAlive: true,
        }
    },
    {
        path: '/imgTemplate',
        name: 'imgTemplate',
        component: () => import('@/views/personnelManagement/commodity/hmImgTemplate/index.vue'),
        meta: {
            title: '头图模板库',
            keepAlive: true,
        }
    },
    {
        path: '/takeOrderRecord',
        name: 'serviceStaffTakeOrderRecord',
        component: () => import('@/views/personnelManagement/serviceStaff/takeOrdersRecord/index.vue'),
        meta: {
             title: '接单记录',
             keepAlive: true,
        }
    },
    {
        path: '/serviceStaffFlow',
        name: 'serviceStaffFlow',
        component: () => import('@/views/personnelManagement/serviceStaff/flow/index.vue'),
        meta: {
             title: '资金流水',
             keepAlive: true,
        }
    },
    {
        path: '/takeOrderReward',
        name: 'takeOrderReward',
        component: () => import('@/views/personnelManagement/serviceStaff/takeOrderReward/index.vue'),
        meta: {
             title: '接单奖励',
             keepAlive: true,
        }
    },
    {
        path: '/takeReward',
        name: 'takeReward',
        component: () => import('@/views/personnelManagement/serviceStaff/takeOrderReward/reward.vue'),
        meta: {
             title: '接单奖励明细列表',
        }
    }
]