import Vue from 'vue'
import App from './App.vue'
// import 'default-passive-events'
import init from './utils/init'
import api from './utils/api'
import components from "./components/index"
import store from './store/index'
import router from './route/router'
import '@/components/Element/element'
import VueContextMenu from 'vue-contextmenu'
import Print from 'vue-print-nb'
import _ from 'lodash'
import "uno.css";
import VueSocketIO from "vue-socket.io";
Vue.use(VueContextMenu)
Vue.use(Print);
let uri = process.env.VUE_APP_CONNECTION_URL;
if(localStorage.getItem("token")){
  uri += "/?token=" + localStorage.getItem("token");
}
Vue.use(new VueSocketIO({debug: init.debug, connection: uri,reconnect: true, reconnectionAttempts: 5, reconnectionDelay: 3000, reconnectionDelayMax: 5000}))
// 自动注册组件
Object.keys( components ).forEach((item) => {Vue.component(item, components[item])})
Vue.config.productionTip = false
Vue.prototype.$config = init;
Vue.prototype.$curl = api;
// Vue.prototype.$utils = utils;
Vue.prototype._ = _
new Vue({
  sockets: {
    connecting() {
      console.log('正在连接')
    },
    disconnect() {
      console.log("Socket 断开");
    },
    connect_failed() {
      console.log('连接失败')
    },
    connect_error() {
      console.error('连接失败');
    },
    connect() {
      console.log('socket connected')
    },
    error(error) {
      console.log('socket error', error)
    },
    reconnecting() { // 正在重连
      console.log('socket reconnecting')
    },
    reconnect() { // 重连成功
      console.log('socket reconnecting')
    }
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
